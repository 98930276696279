import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '@shared/services/language.service';
import {
  IStepOption,
  TourMatMenuModule,
  TourService,
} from 'ngx-ui-tour-md-menu';
import { HeaderSearchService } from './shared/services/header-search/header-search.service';
import { CommonTourService } from '@shared/services/commonTour/common-tour-service';
import { NgIf } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PopupComponent } from './shared/components/popup/popup.component';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TranslateModule,
    TourMatMenuModule,
    RouterModule,
    NgIf,
    MatMenuModule,
    MatIcon,
    MatCardModule,
    MatDialogModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private Commontourservice: CommonTourService,
    private translateService: TranslateService,
  private languageService: LanguageService,
    private headerservice: HeaderSearchService,
    public tourService: TourService,
    private dialog: MatDialog,
  ) {
    const lang: string = this.languageService.getLanguage();
    this.translateService.setDefaultLang(lang);
  }

  ngOnInit(): void {
   
    const isOpen= JSON.parse(sessionStorage.getItem('isOpen') as string)
   if(!JSON.parse(isOpen)){
    this.dialog.open(PopupComponent);
    
   }
  

    
   
  }

 
}
