import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: 'cdm-metrics/my-dashboard', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () =>
      import('@layout/layout.routing.module').then(
        (mod) => mod.LayoutRoutingModule
      ),
  },
  {
    path: 'proxy',
    loadComponent: () =>
      import('@core/proxy/proxy.component').then((mod) => mod.ProxyComponent),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('@core/login/login.component').then((mod) => mod.LoginComponent),
  },
  {
    path: 'error',
    loadComponent: () =>
      import('@core/error/error.component').then((mod) => mod.ErrorComponent),
  },
  {
    path: 'callback',
    loadComponent: () =>
      import('@core/callback/callback.component').then(
        (mod) => mod.CallbackComponent
      ),
  },
];
